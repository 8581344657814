import { openDB } from 'idb';

const DB_NAME = 'offline-db';
const STORE_NAME = 'offline-requests';

async function openOfflineDB() {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME, { autoIncrement: true });
    },
  });
}

export async function saveOfflineRequest(request) {
  console.log('request', request);

  if (request.method === 'post') {
    const db = await openOfflineDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    store.add(JSON.parse(JSON.stringify(request)));
    await tx.done;
  }
}

export async function getOfflineRequests() {
  const db = await openOfflineDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  return store.getAll();
}

export async function clearOfflineRequests() {
  const db = await openOfflineDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  store.clear();
  await tx.done;
}