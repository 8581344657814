import { saveOfflineRequest } from '@/services/offlineService';
import { HTTP } from '@/http/index';
import store from '@/store';
import i18n from '@/i18n';

export default function setup() {
  HTTP.interceptors.request.use(
    async (config) => {
      store.dispatch('application/startLoading');

      // Check if online
      if (navigator.onLine) {
        return config;
      }

      // Save request in IndexedDB
      await saveOfflineRequest(config);
      return Promise.reject(new Error('Offline mode, request saved.'));
    },
    (error) => {
      store.dispatch('application/finishLoading');
      return Promise.reject(error);
    },
  );

  HTTP.interceptors.response.use(
    (response) => {
      store.dispatch('application/finishLoading');
      if (response.status < 300) {
        if (response.config.alertMessages && response.config.alertMessages.successKey) {
          store.dispatch('application/showAlert', {
            messages: [`${i18n.global.t(response.config.alertMessages.successKey)}`],
            type: 'success',
            mode: 'automatic',
          });
        }
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    },
    (error) => {
      store.dispatch('application/finishLoading');
      if (error.response) {
        if (error.response.status) {
          switch (error.response.status) {
            case 400:
              if (error.config.alertMessages && error.config.alertMessages.errorKey) {
                store.dispatch('application/showAlert', {
                  messages: [i18n.global.t(error.config.alertMessages.errorKey)],
                  type: 'danger',
                  mode: 'automatic',
                });
              } else {
                store.dispatch('application/showAlert', {
                  messages: [i18n.global.t(`alert.errors.${error.response.data.errorKey}`)],
                  type: 'danger',
                  mode: 'automatic',
                });
              }
              
              // if (error.response.data.message.includes('Invalid login credentials')) {
                
              // }
              
              // if (error.response.data.error.includes('No active target found')) {
              //   console.log('kill it');
              //   store.dispatch('application/showAlert', {
              //     messages: [i18n.global.t('alert.errors.noActiveTargetFound')],
              //     type: 'danger',
              //     mode: 'automatic',
              //   });
              // }
              
              break;

            case 401:
              localStorage.clear();
              location.reload();
  
              // if (error.config.alertMessages !== undefined) {
              //   if (error.config.alertMessages.errorKey === 'alert.errors.login') {
              //     store.dispatch('application/showAlert', {
              //       messages: [`${i18n.t('alert.errors.messageStart')} :
              //     ${i18n.t(error.config.alertMessages.errorKey)}`],
              //       type: 'error',
              //       mode: 'automatic',
              //     });
              //   } else {
              //     store.dispatch('auth/logout');
              //   }
              // } else {
              //   store.dispatch('auth/logout');
              // }
  
              break;
  
            case 422:
              if (error.config.alertMessages && error.config.alertMessages.errorKey) {
                store.dispatch('application/showAlert', {
                  messages: [i18n.global.t(error.config.alertMessages.errorKey)],
                  type: 'danger',
                  mode: 'automatic',
                });
              }
              
              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).map((key) => {
                  store.dispatch('application/showAlert', {
                    messages: [i18n.global.t(`alert.errors.${error.response.data.errors[`${key}`][0]}`)],
                    type: 'danger',
                    mode: 'automatic',
                  });
                });
              }

              break;
  
            case 500:
              store.dispatch('application/showAlert', {
                messages: [i18n.global.t('alert.errors.serverErrorPleaseTryAgain')],
                type: 'danger',
                mode: 'automatic',
              });
              break;
          }
        }
      }
      
      return Promise.reject(error);
    },
  );
}