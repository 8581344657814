import * as types from './types';

export default {
  [types.SET_ZONES_OF_MINE](state, zones) {
    state.zones = zones;
  },
  [types.SET_TARGET_OF_ZONE](state, target) {
    state.target = target;
  },
  [types.SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
};