import dashboardApi from '@/api/dashboard';
import * as types from './types';

export default {
  getDashboards({ commit }, params) {
    return dashboardApi.findAll(params)
      .then((response) => {
        commit(types.SET_DASHBOARD_DATA, response.data);
        return response;
      }).catch((error) => error)
  },

  getActiveShift({ commit }) {
    console.log("nous passons par la banduku");
    return dashboardApi.getActiveShift()
      .then((response) => {
        commit(types.SET_ACTIVE_SHIFT, response.data);
        return response;
      }).catch((error) => error)
  }
};