import * as types from './types';

export default {
  [types.SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },
  [types.SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [types.SET_USERS](state, users) {
    state.users = users;
  },
  [types.SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [types.SET_CURRENT_PAGE](state, currentPage) {
    state.pagination.current_page = currentPage;
  },
};