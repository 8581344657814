import store from "@/store";

export default [
  // Tablet
  {
    path: "/operations/home",
    name: "home",
    meta: { authRequired: true},
    component: () => import("./views/tablet/operations/home"),
  },
  {
    path: "/operations/create",
    name: "New operation",
    meta: { authRequired: true},
    component: () => import("./views/tablet/operations/create"),
  },
  // Desktop
  {
    path: "/configurations",
    name: "configurations",
    meta: { authRequired: true},
    component: () => import("./views/configurations/configurations"),
  },
  {
    path: "/shifts",
    name: "shifts",
    meta: { authRequired: true},
    component: () => import("./views/shifts/shifts"),
  },
  {
    path: "/mines/:siteId",
    name: "mines",
    meta: { authRequired: true},
    component: () => import("./views/configurations/mines"),
  },
  {
    path: "/zones/:mineId",
    name: "minesZones",
    meta: { authRequired: true},
    component: () => import("./views/configurations/zones"),
  },
  {
    path: "/drivers",
    name: "driver",
    meta: { authRequired: true},
    component: () => import("./views/drivers/drivers"),
  },
  {
    path: "/activities",
    name: "activities",
    meta: { authRequired: true},
    component: () => import("./views/activities/activities"),
  },
  {
    path: "/dump-trucks",
    name: "dump truck",
    meta: { authRequired: true},
    component: () => import("./views/dumpTrucks/dumpTrucks"),
  },
  {
    path: "/operations",
    name: "operation",
    meta: { authRequired: true},
    component: () => import("./views/operations/operations"),
  },
  {
    path: "/targets",
    name: "targets",
    meta: { authRequired: true},
    component: () => import("./views/targets/targets"),
  },
  {
    path: "/",
    name: "dashbaord",
    meta: { authRequired: true},
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/roles",
    name: "roles",
    meta: { authRequired: true},
    component: () => import("./views/roles/roles"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "dashbaord" } : { ...routeFrom }
        );
      },
    },
  }
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
];