import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from 'vue3-scroll-spy';

import Maska from 'maska'
import i18n from "./i18n"
import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';

import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import interceptorsSetup from '@/http/interceptors';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import store from "./store";

import "../src/design/app.scss";
import './registerServiceWorker'

import VDatePicker from 'v-calendar';
import 'v-calendar/style.css';

interceptorsSetup();


createApp(App)
  .use(store)
  .use(router)
  .use(require('vue-chartist'))
  .use(BootstrapVueNext)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .use(Maska)
  .use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  })
  .use(VDatePicker, {})
  .mount('#app')
