import { setAuthorization } from '@/http/index';
import authApi from '@/api/auth';
import * as types from './types';

export default {
  checkAuthorization({ commit }, response) {
    setAuthorization(response.data.token);
    commit(types.SET_USER_INFO, response.data.user);
    commit(types.SET_USER_INFO_STATE, response.data.user);
    commit(types.SET_USER_SESSION_DATA, response.data.token);
  },
  async login({ dispatch }, credentials) {
    return await authApi.login(credentials)
      .then((response) => {
        dispatch('auth/checkAuthorization', response, { root: true });

        return response;
      }).catch((error) => {
        return error
      });
  },
  logout({commit}) {
    return authApi.logout().then(() => {
      commit(types.LOGOUT_USER);
    });
  },
  check({ commit }) {
    const sessionString = localStorage.getItem('ugdb-session');
    const userString = localStorage.getItem('user');
    if (sessionString) {
      const sessionData = JSON.parse(sessionString);
      const user = JSON.parse(userString);
      setAuthorization(sessionData);
      commit(types.SET_USER_INFO_STATE, user);
    }
  },
};