import configurationApi from '@/api/configuration';
import * as types from './types';

export default {
  getConfigurations({ commit }, params) {
    return configurationApi.findAllData(params)
      .then((response) => {
        const sortedConfigs = [];

        response.data.data.forEach(config => {
          config.mines.forEach(mine => {
              mine.zones.forEach(zone => {
                sortedConfigs.push({
                  siteName: config.name,
                  mineName: mine.name,
                  zoneName: zone.name,
                  id: config.id
                });
              });
          });
        });

        commit(types.SET_CONFIGURATIONS, sortedConfigs);
        return response;
      }).catch((error) => error)
  },
  getAllSites({ commit }, params) {
    return configurationApi.findAllSites(params)
      .then((response) => {
        commit(types.SET_SITES, response.data);
        return response;
      }).catch((error) => error)
  },
  getUnpaginateSites({ commit }, params) {
    return configurationApi.fecthUnpaginateSites(params)
      .then((response) => {
        commit(types.SET_UNPAGINATE_SITES, response.data);
        return response;
      }).catch((error) => error)
  },
  getAllMines({ commit }, params) {
    return configurationApi.findAllMines(params)
      .then((response) => {
        commit(types.SET_MINES, response.data.data);
        return response;
      }).catch((error) => error)
  },
  getUnpaginateMines({ commit }, params) {
    return configurationApi.fecthUnpaginateMines(params)
      .then((response) => {
        
        commit(types.SET_UNPAGINATE_MINES, response.data);
        return response;
      }).catch((error) => error)
  },
  getUnpaginateZones({ commit }, params) {
    return configurationApi.fecthUnpaginateZones(params)
      .then((response) => {
        commit(types.SET_UNPAGINATE_ZONES, response.data);
        return response;
      }).catch((error) => error)
  },
  getAllZones({ commit }, params) {
    return configurationApi.findAllZones(params)
      .then((response) => {
        commit(types.SET_ZONES, response.data.data);
        return response;
      }).catch((error) => error)
  },
  getMinesBySiteId({ commit }, params) {
    return configurationApi.findMinesBySiteId(params)
      .then((response) => {
        commit(types.SET_SITESMINES, response.data);
        return response;
      }).catch((error) => error)
  },
  getZonesByMineId({ commit }, params) {
    return configurationApi.findZonesByMineId(params)
      .then((response) => {
        commit(types.SET_MINESZONES, response.data);
        return response;
      }).catch((error) => error)
  },
  createSiteConfiguration(context, payload) {
    return configurationApi.createSite(payload)
    .then((response) => response)
    .catch((error) => error)
  },
  createMineConfiguration(context, payload) {
    return configurationApi.createMine(payload)
    .then((response) => response)
    .catch((error) => error)
  },
  createZoneConfiguration(context, payload) {
    return configurationApi.createZone(payload)
    .then((response) => response)
    .catch((error) => error)
  }
};