import { createStore } from 'vuex'

import modulesCalled from './modules'
import configuration from './modules/configuration'
import layout from './modules/layout'
import application from './modules/application'
import dumpTruck from './modules/dumpTruck'
import driver from './modules/driver'
import operation from './modules/operation'
import activity from './modules/activity'
import target from './modules/target'
import dashboard from './modules/dashboard'
import auth from './modules/auth'
import role from './modules/role'
import shift from './modules/shift'

const store = createStore({
  modulesCalled,
  modules: {
    configuration,
    layout,
    application,
    dumpTruck,
    driver,
    operation,
    activity,
    target,
    dashboard,
    auth,
    role,
    shift
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store

