import { createWebHistory, createRouter } from "vue-router";

import store from '@/store'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})


router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('ugdb-session');
  const isMobileOrTablet = checkIfDeviceIsMobileOrTablet();

  const tabletNames = ['home', 'New operation'];

  if (typeof store.state.token === 'undefined') {
    store.dispatch('auth/check');
  }

  if (to.matched.some(record => record.meta.authRequired) && !isAuthenticated) {
    next({ name: 'login' })
  } else if (to.matched.some(record => record.meta.authRequired) && isAuthenticated) {
    if (to.name !== 'login') {
      if (tabletNames.includes(to.name) && isMobileOrTablet) {
        next()
      } else if (!tabletNames.includes(to.name) && isMobileOrTablet) {
        next({ name: 'home' })
      } else if (tabletNames.includes(to.name) && !isMobileOrTablet) {
        next({ name: 'dashbaord' })
      } else {
        next()
      }
    } else {
      next({ name: 'dashboard' })
    }
  } else if (to.matched.some(record => !record.meta.authRequired) && isAuthenticated) {
    if (to.name === 'login') {
      next({ name: 'dashbaord' })
    } else next()
  } else {
    next()
  }
});

function checkIfDeviceIsMobileOrTablet() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export default router
