import * as types from './types';

export default {
  [types.SET_CONFIGURATIONS](state, configurations) {
    state.configurations = configurations;
  },
  [types.SET_SITES](state, sites) {
    state.sites = sites;
  },
  [types.SET_UNPAGINATE_SITES](state, sites) {
    state.unpaginateSites = sites;
  },
  [types.SET_UNPAGINATE_MINES](state, mines) {
    state.unpaginateMines = mines;
  },
  [types.SET_MINES](state, mines) {
    state.mines = mines;
  },
  [types.SET_ZONES](state, zones) {
    state.zones = zones;
  },
  [types.SET_SITESMINES](state, sitesMines) {
    state.sitesMines = sitesMines;
  },
  [types.SET_MINESZONES](state, minesZones) {
    state.minesZones = minesZones;
  },
};