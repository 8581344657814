import { HTTP } from '@/http/index';

export default {
  findAll(params) {
    return HTTP.get('/dashboard', { params });
  },

  getActiveShift() {
    return HTTP.get('/active-shift');
  }
};