import dumpTruckApi from '@/api/dumpTruck';
import * as types from './types';

export default {
  getDumpTrucks({ commit }, params) {
    return dumpTruckApi.findAll(params)
      .then((response) => {
        commit(types.SET_DUMPTRUCKS, response.data.data);
        commit(types.SET_PAGINATION, response.data.meta);
        return response;
      }).catch((error) => error)
  },
  getDumpTruckById({ commit }, dumpTruckId) {
    return dumpTruckApi.findById(dumpTruckId)
      .then((response) => {
        commit(types.SET_DUMPTRUCK, response.data.data);
        return response;
      }).catch((error) => error)
  },
  getUnlinkedDumpTrucks({ commit }) {
    return dumpTruckApi.findUnlikedDumpTrucks()
      .then((response) => {
        commit(types.SET_UNLINKED_DUMPTRUCKS, response.data);
        // commit(types.SET_PAGINATION, response.data.meta);
        return response;
      }).catch((error) => error);
  },
  createDumpTruck(context, payload) {
    return dumpTruckApi.create(payload)
      .then((response) => response)
      .catch((error) => error)
  },
  updateDumpTruck({ commit }, payload) {
    return dumpTruckApi.update(payload)
      .then((response) => {
        commit(types.UPDATE_DUMPTRUCK, response.data);
        return response;
      }).catch((error) => error)
  },
  deleteDumpTruck({ commit }, dumpTruckId) {
    return dumpTruckApi.delete(dumpTruckId)
      .then((response) => {
        commit(types.DELETE_DUMPTRUCK, response.data);
        return response;
      }).catch((error) => error)
  },
  unboundDumpTruck(context, dumpTruckId) {
    return dumpTruckApi.unboundDumpTruck(dumpTruckId)
      .then((response) => {
        return response;
      }).catch((error) => error)
  }
};