export default {
  roles: [],
  permissions: [],
  users: [],
  pagination: {
    last_page: undefined,
    to: undefined,
    total: undefined,
    current_page: undefined,
    from: undefined,
  },
};